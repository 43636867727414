.form-check-input[type=radio] {
    border-radius: 50%;
    margin-right: 7px;
}

.form-check-input:checked {
    background-color: #FF5500;
    border-color: #FF5500;
}

.form-check-input {
    border-color: rgb(176, 176, 176);
}